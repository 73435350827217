@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
   height: 100%;
}

body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
}

.bar {
   position: relative;
   height: 2px;
   width: 500px;
   margin: 0 auto;
   background: #fff;
   margin-top: 150px;
}

.circle {
   position: absolute;
   top: -30px;
   margin-left: -30px;
   height: 60px;
   width: 60px;
   left: 0;
   background: #fff;
   border-radius: 30%;
   animation: normal;
   -webkit-animation: move 5s infinite;
}

.spinnerP {
   position: absolute;
   top: -35px;
   right: -85px;
   text-transform: uppercase;
   color: #6b7280;
   font-family: helvetica, sans-serif;
   font-weight: bold;
   text-decoration-color: #6b7280;
}

@keyframes move {
   0% {
      left: 0;
   }
   50% {
      left: 100%;
      -webkit-transform: rotate(450deg);
      width: 150px;
      height: 150px;
   }
   75% {
      left: 100%;
      -webkit-transform: rotate(450deg);
      width: 150px;
      height: 150px;
   }
   100% {
      right: 100%;
   }
}
